<template>
  <div class="graph-container">
    <h4 style="width: fit-content" class="title">
      {{ title }}<tooltip v-bind:text="toolTipText" v-if="useToolTip"> </tooltip>
    </h4>
    <bar-chart v-if="type === 'bar'" :chart-data="chartData" :options="options" :height="height"></bar-chart>
    <line-chart v-if="type === 'line'" :chart-data="chartData" :options="options" :height="height"></line-chart>
  </div>
</template>

<script>
import { Bar, Line, mixins } from "vue-chartjs";
import Tooltip from "@/components/common/Tooltip";
const { reactiveProp } = mixins;

const BarChart = {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};

const LineChart = {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};

export default {
  components: {
    BarChart,
    LineChart,
    Tooltip,
  },
  props: {
    type: {
      type: String,
      default: "bar",
    },
    title: {
      type: String,
    },
    height: {
      type: Number,
      default: 250,
    },
    chartOptions: {
      type: Object,
    },
    chartData: {
      type: Object,
      required: true,
    },
    useToolTip: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    options() {
      return (
        this.chartOptions || {
          responsive: true,
          legend: {
            position: "bottom",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 20,
                  callback: (v) => v + "%",
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                return Math.round(tooltipItems.yLabel) + "%";
              },
            },
          },
        }
      );
    },
    toolTipText() {
      return "Order and sell-through units are shown as a percentage of total units based on the category segmentation. Example: For the Gender category, Men's Senior accounts for 35% of the total order while Men's Int. accounts for 30% of the total order. For sell-through, based on the past two seasons, Men's Senior accounts for 36% of the total sell-through and Men's Int. accounts for 25% of the total sell-through.";
    },
  },
};
</script>

<style>
.graph-container {
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
}
</style>
