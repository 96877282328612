<template>
  <div>
    <div class="flex-box space-between">
      <h4>
        Benchmark Graphs<tooltip v-bind:text="seasons"></tooltip>
        <p class="graph-desc">
          Graphs show open orders + planned orders compared to the past two seasons of sell-through units (individual
          retailer, region, country & market sell-through). ST= Sell-Through (POS Data)
        </p>
      </h4>
      <div class="aselect">
        <div class="selector" @click="dropdownOpen = !dropdownOpen">
          <div class="label">
            <span>{{ dropdownLabel }}</span>
          </div>
          <div class="arrow" :class="{ expanded: dropdownOpen }"></div>
          <div :class="{ hidden: !dropdownOpen, dropdownOpen }">
            <ul class="scrollable-list">
              <li @click="filter = null">All Data</li>
              <li v-for="filter in filters" :key="filter.value" @click="setFilter(filter)">
                {{ filter.label }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="planning__body-block flex-box flex-column">
      <div class="row justify-content-center charts-container">
        <div class="col-4 pt-0">
          <chart title="GENDER" :chart-data="chartData('gender')" :useToolTip="true"></chart>
        </div>
        <div class="col-4 pt-0">
          <chart title="FAMILY" :chart-data="chartData('family')" :useToolTip="false"></chart>
        </div>
        <div class="col-4 pt-0">
          <chart title="SEGMENT" :chart-data="chartData('segment')" :useToolTip="false"></chart>
        </div>
        <template v-if="product.id === 'hockey_skates'">
          <div class="col-4 pb-0">
            <chart title="Fit" :chart-data="chartData('dim1', config.hockey_skates.standardFit)"></chart>
          </div>
          <div class="col-4 pb-0">
            <chart title="Width" :chart-data="chartData('dim1', config.hockey_skates.alternateFit)"></chart>
          </div>
        </template>
        <template v-else>
          <div class="col-4 pb-0">
            <chart title="DIM1" :chart-data="chartData('dim1')" :useToolTip="false"></chart>
          </div>
        </template>
        <template>
          <div class="col-4 pb-0">
            <chart title="DIM2" type="line" :chart-data="chartData('dim2')" :useToolTip="false"></chart>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapGetters } from "vuex";

import Chart from "@/components/charts/Chart";
import Tooltip from "@/components/common/Tooltip";
import { sortBy } from "lodash";

export default {
  components: {
    Chart,
    Tooltip,
  },
  computed: {
    ...mapGetters([
      "retailer",
      "product",
      "materialData",
      "season",
      "bookingData",
      "sellThruData",
      "countryData",
      "marketData",
      "regionData",
      "currentSeasonYear",
      "actuals",
      "firstDimension",
    ]),

    seasons() {
      const text = this.getPreviousSeasonText();
      return `Graphs show current order (based on units in SKU data below) compared to the past two seasons (${text}) of sell-through units (individual retailer, region, country & market sell-through). ST = Sell-Through (POS Data)`;
    },
    currentSeason() {
      const fullYear = this.currentSeasonYearFunc();
      return "S'" + (fullYear % 100);
    },
    benchMarkDesc() {
      const fullYear = this.currentSeasonYearFunc();
      let LastSecondYear = fullYear - 2;
      const text = this.getPreviousSeasonText();
      return text + " (April " + LastSecondYear;
    },
    startDate() {
      return `${this.season.year - 2}-04-01`;
    },
    endDate() {
      return `${this.season.year}-03-31`;
    },
    dropdownLabel() {
      if (!this.filter) return "All Data";
      return this.filter.label;
    },
    filters() {
      let filters = [];

      for (const [k, arr] of Object.entries(this.keys())) {
        arr.forEach((v) => {
          if (v) {
            filters.push({
              key: k,
              value: v,
              valueKey: v.replace("Men's ", "").replace(".", "").toLowerCase(),
              label: `${_.capitalize(k)} - ${v.replace("Men's ", "")}`,
            });
          }
        });
      }

      return filters;
    },
  },
  data() {
    return {
      inited: false,
      dropdownOpen: false,
      Region: null,
      Country: null,
      Market: null,
      SellThru: null,
      groups: {
        Order: null,
        SellThru: 112,
        Region: 114,
        Country: 113,
        Market: 115,
      },
      colors: {
        Order: "#efb71e",
        SellThru: "purple",
        Region: "#c41130",
        Country: "#4bd23d",
        Market: "#009ACD",
      },
      disableDim2: false,
      config: {
        hockey_sticks: {
          hasDim2: true,
        },
        hockey_skates: {
          hasDim2: true,
          standardFit: {
            keys: ["FIT1", "FIT2", "FIT3"],
          },
          alternateFit: {
            omit: ["FIT1", "FIT2", "FIT3"],
          },
          filtered: {
            gender: {
              dim2: {
                senior: {
                  minMax: [7, 15],
                },
                int: {
                  minMax: [4, 6.5],
                },
                junior: {
                  minMax: [1, 3.5],
                },
                youth: {
                  minMax: [8, 13.5],
                },
              },
            },
          },
        },
        hockey_helmets: {
          hasDim2: true,
          dim2: {
            keys: ["XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
          },
        },
        hockey_gloves: {
          hasDim2: true,
          filtered: {
            gender: {
              dim2: {
                senior: {
                  minMax: [14, 15],
                },
                int: {
                  minMax: [12, 13],
                },
                junior: {
                  minMax: [10, 11],
                },
                youth: {
                  minMax: [8, 9],
                },
              },
            },
          },
        },
        hockey_pants: {
          hasDim2: true,
          dim2: {
            keys: ["XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL"],
          },
        },
        hockey_shin: {
          hasDim2: true,
          filtered: {
            gender: {
              dim2: {
                senior: {
                  minMax: [14, 17],
                },
                int: {
                  minMax: [13, 14],
                },
                junior: {
                  minMax: [11, 13],
                },
                youth: {
                  minMax: [8, 10],
                },
              },
            },
          },
        },
        hockey_shoulder: {
          dim1: {
            keys: ["S", "M", "L", "XL"],
          },
        },
        hockey_elbow: {
          dim1: {
            keys: ["S", "M", "L", "XL"],
          },
        },
      },
      filter: null,
    };
  },
  methods: {
    getPreviousSeasonText() {
      const fullYear = this.currentSeasonYearFunc();
      const lastTwoDigitsOfYear = fullYear % 100;
      const text = "S'" + (lastTwoDigitsOfYear - 2) + " and S'" + (lastTwoDigitsOfYear - 1);
      return text;
    },
    keys() {
      let o = {
        gender: [],
        family: [],
        segment: [],
        dim1: [],
        dim2: [],
      };
      // AGGREGATE ALL POSSIBLE KEYS
      for (const [attr] of Object.entries(o)) {
        o[attr] = _(_.concat(this.Order(), this.SellThru, this.Region, this.Country, this.Market))
          .map(attr.toLowerCase())
          .uniq()
          .value();

        //Sort segment AE-59
        if (attr === "segment") {
          o[attr].sort((a, b) => {
            const order = ["Elite", "Performance", "Recreational"];
            return order.indexOf(a) - order.indexOf(b);
          });
        }

        //Sort dimension 1 AE-59
        if (attr === "dim1") {
          o[attr] = sortBy(o[attr], (product) => this.firstDimension[product]);
        }

        // SORT DIM2
        if (attr === "dim2")
          o[attr].sort(function (a, b) {
            return Number(a) - Number(b);
          });
      }

      this.disableDim2 = o["dim2"].length === 0 || (o["dim2"].length === 1 && o["dim2"][0] === "true");
      return o;
    },
    Order() {
      const data = this.$materialAllocation.getFlattenedUpcData(this.actuals);
      return data;
    },
    currentSeasonYearFunc() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      let fullYear = currentDate.getFullYear();

      if (currentMonth >= 3) {
        fullYear += 1;
      }
      return fullYear;
    },
    chartData(metric) {
      const groupKey = this.keys();
      let chartData = {
        labels: groupKey[metric],
        datasets: [],
      };
      //clear dim2 labels if there are no dim2 or the only dim2 is labelled true
      if (metric === "dim2" && this.disableDim2) {
        chartData.labels = [];
      }

      const newGroupMapping = {
        Order: "Order",
        SellThru: "Retailer ST",
        Region: "Region ST",
        Country: "Country ST",
        Market: "Market ST",
      };

      if (this.filter) {
        const filterConfig = _.get(this.config, [
          this.product.id,
          "filtered",
          this.filter.key,
          metric,
          this.filter.valueKey,
        ]);
        if (filterConfig) {
          if (filterConfig.minMax) {
            chartData.labels = chartData.labels.filter(
              (v) => Number(v) >= filterConfig.minMax[0] && Number(v) <= filterConfig.minMax[1]
            );
          }
        }
      }

      for (const [group] of Object.entries(this.groups)) {
        let dataset = {};
        if (group === "Order") {
          dataset = _.groupBy(this.Order(), metric);
        } else {
          dataset = _.groupBy(this[group], metric);
        }
        let data = _.fill(Array(chartData.labels.length), 0);

        chartData.labels.forEach((value, i) => {
          data[i] = _.chain(dataset[value]).filter(this.getFilterObj(metric)).map("units").sum().value();
        });

        chartData.datasets.push({
          label: newGroupMapping[group],
          backgroundColor: this.colors[group],
          fill: false,
          borderColor: this.colors[group],
          data: this.mapToPercentages(data),
        });
      }

      // CLEAN UP AXIS LABELS FOR NUMBERS
      chartData.labels = chartData.labels
        .map((l) => {
          if (isNaN(l)) return l;
          return Number(l);
        })
        .filter((item) => item);

      // //sort segment data with elite , performance and recreational
      // if (metric === "segment") {
      //   chartData.labels.sort((a, b) => {
      //     const order = ["Elite", "Performance", "Recreational"];
      //     return order.indexOf(a) - order.indexOf(b);
      //   });
      // }

      console.log(chartData);

      return chartData;
    },
    getFilterObj(key) {
      if (!this.filter) return null;

      // DO NOT FILTER DATASET IF CHART IS TARGET FILTER
      if (key === this.filter.key) return null;
      return [this.filter.key, this.filter.value];
    },
    async analyseGraphData() {
      for (const [k, v] of Object.entries(this.groups)) {
        if (!v) continue;
        let data = this.getDataForFilter(v);
        this[k] = _.chain(data)
          .map((o) => _.pick(o, ["units", "dim1", "dim2", "family", "gender", "segment"]))
          .value();
      }
    },
    getDataForFilter(v) {
      switch (v) {
        case 112:
          return this.sellThruData;
        case 113:
          return this.countryData;
        case 114:
          return this.regionData;
        case 115:
          return this.marketData;
        default:
          return [];
      }
    },
    mapToPercentages(a) {
      const total = _.sum(a);
      return a.map((v) => {
        return (v / total) * 100;
      });
    },
    setFilter(filter) {
      this.filter = filter;
    },
  },
  watch: {
    sellThruData() {
      this.analyseGraphData();
    },
    countryData() {
      this.analyseGraphData();
    },
    marketData() {
      this.analyseGraphData();
    },
    regionData() {
      this.analyseGraphData();
    },
  },
  mounted() {
    this.analyseGraphData();
    this.$materialAllocation.$on("upcAllocated", () => {
      setTimeout(() => {
        this.analyseGraphData();
      }, 1000); // Delay of 3000 milliseconds (3 seconds)
    });
  },
};
</script>

<style scoped>
.graph-desc {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}

.graph-span {
  font-weight: 500;
}

.aselect {
  margin: 0 60px 0 0;
}
</style>
