<template>
    <div class="tool planning__body">

        <BookingsCharts v-if="showBookingsCharts" :key="bookingsChartsKey" />

        <h4>Suggested Order</h4>
        <div>
            <Loading class="py-5" v-if="tableUpcLoader" />
            <div class="planning__body-block flex-box flex-column" v-else>
                <TableUPC v-if="showTableUPC" :key="tableUPCKey" />
            </div>
        </div>


        <h4>Summary</h4>
        <div class="planning__body-block flex-box flex-column">
            <TableSummary v-if="showTableSummary" :key="tableSummaryKey" />
        </div>

    </div>
</template>

<script>
import BookingsCharts from '@/components/planning/bookings/BookingsCharts';
import TableUPC from '@/components/planning/bookings/TableUPC';
import TableSummary from '@/components/planning/bookings/TableSummary';
import { mapGetters } from 'vuex';
import Loading from '@/components/common/Loading';

export default {
    components: {
        BookingsCharts,
        TableUPC,
        TableSummary,
        Loading
    },
    data() {
        return {
            bookingsChartsKey: 0,
            tableUPCKey: 0,
            tableSummaryKey: 0,
            showBookingsCharts: true,
            showTableUPC: true,
            showTableSummary: true,
        };
    },
    beforeRouteEnter(to, from, next) {
        // reset page since this page needs to be reloaded whenever you change values in material level page planning
        next(vm => {
            if (to.path === '/planning/edit') {
                // Reset component data and keys
                vm.showBookingsCharts = false;
                vm.showTableUPC = false;
                vm.showTableSummary = false;
                vm.bookingsChartsKey += 1;
                vm.tableUPCKey += 1;
                vm.tableSummaryKey += 1;

                // Allow Vue to remove the components from the DOM
                vm.$nextTick(() => {
                    // Re-enable the components and increment keys
                    vm.showBookingsCharts = true;
                    vm.showTableUPC = true;
                    vm.showTableSummary = true;
                    vm.bookingsChartsKey += 1;
                    vm.tableUPCKey += 1;
                    vm.tableSummaryKey += 1;
                });
            }
        });
    },
    mounted() {
        this.$events.on('changeSettings', () => {
            // Reset state or perform other actions
            this.$store.state.allocationRemainders = {};
        });

        const thisInstance = this;
        this.$root.$on('updatedConfig', function () {
            thisInstance.bookingsChartsKey += 1;
            thisInstance.tableUPCKey += 1;
            thisInstance.tableSummaryKey += 1;
        });
    },
    computed: {
        ...mapGetters(['tableUpcLoader'])
    },
    beforeDestroy() {
        this.$events.off('changeSettings');
    }
};
</script>