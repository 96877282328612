<template>
  <div class="table_summary">
    <div v-if="showAdjstedData">
      <h5>SKU Level - Open and Planned Orders (Units Only)</h5>
      <div
        :class="conditionToDisplay() ? 'table-container' : 'table-container-justify'"
        v-if="selectedAllocationMethod.length"
      >
        <div :class="conditionToDisplay() ? 'sub-table-container' : 'sub-table-container-row'">
          <div class="col-3">
            <table class="table">
              <thead>
                <tr>
                  <th>FAMILY</th>
                  <th>LAUNCH</th>
                  <th>MIGRATING</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(family, index) in families()" :key="index">
                  <td class="sub-heading">{{ family }}</td>
                  <td>
                    {{
                      getSum(actuals, { family: family, launch_type: "launch" }, "units_sold") +
                      plannedFamilyMaterial(family, "launch")
                    }}
                  </td>
                  <td>
                    {{
                      getSum(actuals, { family: family, launch_type: "migrating" }, "units_sold") +
                      plannedFamilyMaterial(family, "migrating")
                    }}
                  </td>
                  <td>{{ getSum(actuals, { family: family }, "units_sold") + plannedFamilyMaterial(family) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-3">
            <table class="table">
              <thead>
                <tr>
                  <th>SEGMENT</th>
                  <th>LAUNCH</th>
                  <th>MIGRATING</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(segment, index) in segments()" :key="index">
                  <td class="sub-heading">{{ segment }}</td>
                  <td>
                    {{
                      getSum(actuals, { segment: segment, launch_type: "launch" }, "units_sold") +
                      plannedSegmentMaterial(segment, "launch")
                    }}
                  </td>
                  <td>
                    {{
                      getSum(actuals, { segment: segment, launch_type: "migrating" }, "units_sold") +
                      plannedSegmentMaterial(segment, "migrating")
                    }}
                  </td>
                  <td>
                    {{ getSum(actuals, { segment: segment }, "units_sold") + plannedSegmentMaterial(segment) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-3">
          <table class="table">
            <thead>
              <tr>
                <th>GENDER</th>
                <th>LAUNCH</th>
                <th>MIGRATING</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(gender, index) in genders()" :key="index">
                <td class="sub-heading">{{ gender }}</td>
                <td>
                  {{
                    getSum(actuals, { gender: gender, launch_type: "launch" }, "units_sold") +
                    plannedGenderMaterial(gender, "launch")
                  }}
                </td>
                <td>
                  {{
                    getSum(actuals, { gender: gender, launch_type: "migrating" }, "units_sold") +
                    plannedGenderMaterial(gender, "migrating")
                  }}
                </td>
                <td>
                  {{ getSum(actuals, { gender: gender }, "units_sold") + plannedGenderMaterial(gender) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-3" v-if="conditionToDisplay()">
          <table class="table">
            <thead>
              <tr>
                <th>DIM1</th>
                <th>LAUNCH</th>
                <th>MIGRATING</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody v-for="(gender, index) in genders()" :key="index">
              <tr class="gender-heading">
                <td class="sub-heading">
                  {{ gender }}
                </td>
              </tr>
              <tr v-for="(dimension, dimensionIndex) in getDimensions('Dim1', gender)" :key="dimensionIndex">
                <td class="sub-heading">{{ dimension }}</td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim1", gender, "launch") +
                    calculatePlannedQuantityByDim(dimension, "Dim1", gender, "launch")
                  }}
                </td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim1", gender, "migrating") +
                    calculatePlannedQuantityByDim(dimension, "Dim1", gender, "migrating")
                  }}
                </td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim1", gender) +
                    calculatePlannedQuantityByDim(dimension, "Dim1", gender)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-3" v-if="conditionToDisplay()">
          <table class="table">
            <thead>
              <tr>
                <th>DIM2</th>
                <th>LAUNCH</th>
                <th>MIGRATING</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody v-for="(gender, index) in genders()" :key="index">
              <tr class="gender-heading">
                <td class="sub-heading">
                  {{ gender }}
                </td>
              </tr>
              <tr v-for="(dimension, dimensionIndex) in getDimensions('Dim2', gender)" :key="dimensionIndex">
                <td class="sub-heading">{{ dimension === "true" ? "N/A" : dimension }}</td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim2", gender, "launch") +
                    calculatePlannedQuantityByDim(dimension, "Dim2", gender, "launch")
                  }}
                </td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim2", gender, "migrating") +
                    calculatePlannedQuantityByDim(dimension, "Dim2", gender, "migrating")
                  }}
                </td>
                <td>
                  {{
                    calculateActualQuantityByDim(dimension, "Dim2", gender) +
                    calculatePlannedQuantityByDim(dimension, "Dim2", gender)
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="message-container">
        <h3>Select an allocation method</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { filtersMixin } from "@/mixins/filters";

export default {
  mixins: [filtersMixin],
  data() {
    return {
      refreshDisabled: false,
      showAdjstedData: true,
    };
  },
  computed: {
    ...mapGetters([
      "actuals",
      "plannedMaterialData",
      "selectedAllocationMethod",
      "firstDimension",
      "secondDimension",
      "product",
    ]),
    families() {
      return () => {
        return this.$materialAllocation.uniqueFamilies;
      };
    },
    genders() {
      return () => {
        return this.$materialAllocation.uniqueGenders.map((item) => item.gender);
      };
    },
    segments() {
      return () => {
        return this.$materialAllocation.uniqueSegments.map((item) => item.segment);
      };
    },
    getDimensions() {
      return (dimensionValue, genderValue) => {
        let dimObject = {};
        Object.values(this.plannedMaterialData).forEach((material) => {
          const materialGender = material.gender;
          const upcs = material.upcs;
          upcs.forEach((upcItem) => {
            if (materialGender === genderValue) {
              dimObject = { ...dimObject, [upcItem[dimensionValue]]: "" };
            }
          });
        });
        const keys = Object.keys(dimObject);
        if (dimensionValue === "Dim1") {
          keys.sort((a, b) => this.firstDimension[a] - this.firstDimension[b]);
        } else {
          keys.sort((a, b) => this.secondDimension[a] - this.secondDimension[b]);
        }
        return keys;
      };
    },
    plannedFamilyMaterial() {
      return (family, type) => {
        return this.calculateQuantityByFamilyAndType(family, type);
      };
    },
    plannedGenderMaterial() {
      return (gender, type) => {
        return this.calculateQuantityByGenderAndType(gender, type);
      };
    },
    plannedSegmentMaterial() {
      return (segment, type) => {
        return this.calculateQuantityBySegmentAndType(segment, type);
      };
    },
    conditionToDisplay() {
      return () => {
        const description = this.product.main_description;
        const name = this.product.name;
        const condition =
          (description === "Ice Hockey Equipment" || description === "Goal Equipment") && name === "Accessories";
        if (condition) {
          return false;
        }
        return true;
      };
    },
  },
  methods: {
    calculateQuantityByFamilyAndType(family, type) {
      const allMaterialsList = this.plannedMaterialData;
      let quantity = 0;
      Object.keys(allMaterialsList).forEach((materialName) => {
        const materialFamily = allMaterialsList[materialName].family;
        const materialType = allMaterialsList[materialName].type;
        if (materialFamily === family && (materialType === type || !type)) {
          quantity = this.calculateUpcPlanned(allMaterialsList[materialName].months, quantity);
        }
      });
      return quantity;
    },
    calculateQuantityByGenderAndType(gender, type) {
      const allMaterialsList = this.plannedMaterialData;
      let quantity = 0;
      Object.keys(allMaterialsList).forEach((materialName) => {
        const materialGender = allMaterialsList[materialName].gender;
        const materialType = allMaterialsList[materialName].type;
        if (materialGender === gender && (materialType === type || !type)) {
          quantity = this.calculateUpcPlanned(allMaterialsList[materialName].months, quantity);
        }
      });
      return quantity;
    },
    calculateQuantityBySegmentAndType(segment, type) {
      const allMaterialsList = this.plannedMaterialData;
      let quantity = 0;
      Object.keys(allMaterialsList).forEach((materialName) => {
        const materialSegment = allMaterialsList[materialName].segment;
        const materialType = allMaterialsList[materialName].type;
        if (materialSegment === segment && (materialType === type || !type)) {
          quantity = this.calculateUpcPlanned(allMaterialsList[materialName].months, quantity);
        }
      });
      return quantity;
    },
    calculateUpcPlanned(monthsValue, quantity) {
      monthsValue.forEach((item) => {
        quantity += item.upcPlanned;
      });
      return quantity;
    },
    calculateActualQuantityByDim(dimensionValue, dimension, genderValue, type) {
      let quantity = 0;
      const upcs = this.getUpcsFromDimension(dimensionValue, dimension, genderValue, type);
      this.actuals.forEach((item) => {
        if (upcs.indexOf(item.upc_number) != -1) {
          quantity += item.units_sold;
        }
      });
      return quantity;
    },
    calculatePlannedQuantityByDim(dimensionValue, dimension, genderValue, type) {
      const allMaterialsList = this.plannedMaterialData;
      let quantity = 0;
      Object.keys(allMaterialsList).forEach((materialName) => {
        const typeCondition = !type || allMaterialsList[materialName].type === type;
        const genderCondition = allMaterialsList[materialName].gender === genderValue;
        allMaterialsList[materialName].upcs.forEach((upcItem) => {
          if (upcItem[dimension] === dimensionValue && typeCondition && genderCondition) {
            upcItem.months.forEach((monthItem) => (quantity += monthItem.planned));
          }
        });
      });
      return quantity;
    },
    getUpcsFromDimension(dimensionValue, dimension, genderValue, type) {
      const allMaterialsList = this.plannedMaterialData;
      let upcs = [];
      Object.keys(allMaterialsList).forEach((materialName) => {
        const typeCondition = !type || allMaterialsList[materialName].type === type;
        const genderCondition = allMaterialsList[materialName].gender === genderValue;
        const filteredUpcs = allMaterialsList[materialName].upcs.reduce((acc, item) => {
          return item[dimension] === dimensionValue && typeCondition && genderCondition
            ? [...acc, item.upcNumber]
            : acc;
        }, []);
        upcs = [...upcs, ...filteredUpcs];
      });
      return upcs;
    },
  },
};
</script>

<style>
.table-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.table-container-justify {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.message-container {
  margin: auto;
  width: fit-content;
}

.sub-table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sub-table-container-row {
  display: flex;
  align-items: flex-start;
}

.gender-heading {
  border-bottom: 2px solid black !important;
}

.sub-heading {
  font-weight: 700 !important;
}

.col-3 {
  max-width: 100%;
}
</style>
